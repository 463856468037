.wrapper {
  display: flex;
  width: 100%;
}

.leftBlock {
  display: flex;
  overflow-y: scroll;
  width: 100%;
  height: calc(100vh - 180px);
  padding-right: 20px;

  @media (max-width: 1240px) {
    overflow-y: unset;
    height: auto;
    padding-right: 0;
  }
}

.content {
  width: 100%;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}


.betButton {
  border: none;
  color: #195BDC !important;
}

.showMore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25.5px;
  @media (max-width: 1024px) {
    margin: 15px 0;
  }
}

.rightBlock {
  // overflow-y: scroll;
  width: 100%;
  // max-width: 335px;
  height: calc(100vh - 180px);
  margin-left: 25px;
  margin-right: -20px;
  padding-right: 20px;

  @media (max-width: 1240px) {
    overflow-y: unset;
    width: 100%;
    max-width: 100%;
    height: auto;
    margin-left: 0;
    padding-right: 0;
  }
}

/*Scrolling fot blocks*/
.leftBlock::-webkit-scrollbar,
.rightBlock::-webkit-scrollbar {
  width: 3px;
  border-radius: 3.5px;
}

.leftBlock::-webkit-scrollbar-thumb,
.rightBlock::-webkit-scrollbar-thumb {
  background: #ACADB0;
  border: 3px solid #ACADB0;
  border-radius: 11px;
}

.leftBlock-dark::-webkit-scrollbar-thumb,
.rightBlock-dark::-webkit-scrollbar-thumb {
  background: #2E3138;
  border: 3px solid #2E3138;
  border-radius: 11px;
}

.headTitleBlock {
  margin-bottom: 20px;
}

.ceilBlock {
  display: flex;
  gap: 50px;
  margin-bottom: 35px;
}

.tabsWrapper {
  // overflow: hidden;
  padding: 0 !important;
  margin-bottom: 25px;
}

.tabs {
  padding-left: 0 !important;
}

.childTabs {
  display: flex;
  flex-direction: column;
  gap: 25px;

  @media (max-width: 1240px) {
    gap: 15px;
  }
}

.rightBlockTitleBlock {
  margin-bottom: 25px;
}


