.wrapper {
  padding: 22px 0 18px 0;
  border-radius: 12px;

}

.default {
  background: #FFFFFF
}


.headerBlock {
  display: flex;
  margin: 0 25px 25px 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #CFD0D1;

  > :first-child {
    margin-right: 20px;
  }

  .centerText {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex: 1;
  }

  @media (max-width: 1024px) {
    margin: 0 15px 15px 15px;
    padding-bottom: 15px;
    flex-direction: column;
    > :first-child {
      margin-bottom: 15px;
    }
  }
}

.button {
  height: 35px;
  padding: 0 15px;
}

.download {
  background-color: #23252D;
  height: 35px;
  width: 35px;
  border-radius: 5px;
  margin-right: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #152338;

    path {
      fill: #0075FF;
    }
  }

  &.mobile {
    width: 100%;
    margin-top: 20px;

    span {
      font-size: 15px;
      color: #FFF;
      margin-left: 15px;
    }
  }
}

.loss {
  background: rgba(250, 62, 44, 0.05);
  color: #FA3E2C;
  height: 35px;
  font-size: 15px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  border-radius: 5px;
}

.waiting {
  background: rgb(247, 147, 26, 0.05);
  color: #F7931A;
  height: 35px;
  font-size: 15px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  border-radius: 5px;
}

.infoBlock {
  margin: 0 25px;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #CFD0D1;

  .infoItem {
    border-left: 1px solid #CFD0D1;
    padding-left: 25px;
    min-width: 120px;
    margin-right: 25px;
  }

  .infoItem:first-child {
    border-left: none;
    padding-left: 0;
  }

  @media (max-width: 1024px) {
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 15px 16px 15px;
    .infoItem {
      padding-left: 15px;
      flex: 1 1 25%;
    }
    .infoItem:nth-child(1),
    .infoItem:nth-child(2) {
      margin-bottom: 15px;
    }
    .infoItem:nth-child(3) {
      margin-bottom: 15px;
      padding-left: 0;
      border-left: none;
    }
    .infoItem:nth-child(4) {
      padding: 0;
      margin: 0;
      border-left: none;
    }
  }

}

.dark {
  color: #FFFFFF;
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);

  .headerBlock {
    border-bottom: 1px solid #2E3138;
    vertical-align: baseline;
  }

  .infoBlock {
    border-bottom: 1px solid #2E3138;
  }

  .infoItem {
    border-left: 1px solid #2E3138
  }
}


.showBets {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  .betButton {
    border: none;
    color: #0075FF;
  }
}

.rotateChevron {
  transform: rotate(180deg);
}

.content {
  overflow: auto;
  padding-bottom: 50px;
}
