.mb_10 {
  margin-bottom: 10px;
}

.mb_25 {
  margin-bottom: 25px;
  @media (max-width: 1024px) {
    margin-bottom: 20px;
  }
}


.divider {
  width: 100%;
  height: 1px;
  background: #CFD0D1;
  margin-bottom: 25px;
  @media (max-width: 1024px) {
    margin-bottom: 15px;
  }
}


.rowBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
}

.prizeBlock {
  display: flex;
  flex-direction: column;
}

.buttonGroup {
  div {
    min-width: 107px;
  }


  @media (max-width: 1024px) {
    display: flex;
    flex: 1 1 100%;
    gap: 11px;
    margin-top: 15px;

    div {
      font-weight: 500;
      font-size: 17px;
      line-height: 20px;
      width: 100%;
      height: 50px;
    }
  }
}


.dark {
  .divider {
    background: #2E3138;
  }
}
