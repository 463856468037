.main {
  font-family: 'Inter', sans-serif;
  overflow-wrap: anywhere;
  font-style: normal;
  font-size: 15px;
  margin: 0;
}


.left {
  text-align: left;
}

.inherit {
  text-align: inherit;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}


// шрифты


//! 15
.title {
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
}

//! 12
.subtitle {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
}

//! 15
.body1 {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

//! 15
.body2 {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
}

//! 25
.h1 {
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
}

//! 20
.h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
}

.h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
}

.h4 {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}

.h5 {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
}

//! 12
.smallBold {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.small {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.ssm {
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
}

//! 10
.extraSmall {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
}
