.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  :global [role="navigation"] {
    display: flex;

    li {
      margin: 0px 6px;

      a {
        display: block;
        background: linear-gradient(0deg, #171A22, #171A22), linear-gradient(180deg, #2E3138 0%, rgba(46, 49, 56, 0.44) 100%);
        color: white;
        background: #171A22;
        border-radius: 10px;
        padding: 13px 17px;
        border: 2px solid #2E3138;
        font-weight: 500;
        white-space: nowrap;
        cursor: pointer;
      }

      &.selected a {
        border: 1px solid transparent;
        padding: 14px 18px;
        background: linear-gradient(#171a22, #171a22) padding-box, linear-gradient(180deg, #0075ff, #2e3138) border-box;
      }

      &.disabled {
        opacity: 0.4;

        a {
          cursor: default;
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    :global [role="navigation"] {
      display: flex;

      li {
        margin: 0px 4px;

        a {
          padding: 7px 11px;
        }
        &.selected a {
          padding: 8px 12px;
        }
      }

    }
  }
}
