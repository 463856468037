.menuTableRow-dark {
  background: #0075FF;
  box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.25);
}


@media(min-width: 1036px) {

  .menuTableRow-dark {
    position: absolute;
    right: 0;
    top: 100%;
  }
}

@media(min-width: 1036px) {
  .menuTableRow-dark::before {
    content: "";
    position: absolute;
    top: -29px;
    right: -3px;
    background-image: url("../../../../components/Icons/deleteBet.svg");
    width: 50px;
    height: 50px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}


.iconCrumbs {
  transform: rotate(90deg);
}

.removeDeal {
  line-height: 15px;
  cursor: pointer;
  user-select: none;
  text-align: center;
}

.circleIcon-default {
  display: flex;
  margin-left: 10px;
  width: 15px;
  height: 15px;

  background: conic-gradient(from 180deg at 50% 50%, #0075FF 0deg, rgba(0, 117, 255, 0.25) 360deg);
  border-radius: 50%;
  @media (max-width: 1180px) {
    margin-right: 0;
  }
}

.circleIcon-dark {
  display: flex;
  margin-left: 10px;
  width: 15px;
  height: 15px;
  background: conic-gradient(from 180deg at 50% 50%, #0075FF 0deg, rgba(21, 35, 56, 0.25) 360deg);
  border-radius: 50%;
  @media (max-width: 1180px) {
    margin-right: 0;
  }
}
