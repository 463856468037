.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background: #101319;

  &.chartLoader {
    background: #171A22;
    border-radius: 10px;
    z-index: 10;
  }
}


.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.text {
  margin-top: 20px;
  font-size: 20px;
  text-align: center;
}
