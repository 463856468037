$grey: #CFD0D1;
$dark: #2E3138;

.wrapper {
  display: flex;
  align-items: center;
}

.iconBlock {
  display: flex;
  margin-right: 10px;
}

.textBlock {
  flex: 1;
}

.rightBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider {
  height: 1px;
  background: $grey;
  margin: 15px 0;
}

.default {
  background: $grey;
}

.dark {
  background: $dark;
}