$primary: #0075FF;
$grey: #CFD0D1;
$darkGrey: #2E3138;

.radio {
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;

  input {
    display: none;

    & + span {
      display: inline-block;
      position: relative;
      padding-left: 30px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        border: 1px solid $grey;
        background: transparent;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background: $primary;
        position: absolute;
        border-radius: 50%;
        opacity: 0;
        transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &:checked + span:after {
      opacity: 1;
    }

    &:checked + span:before {
      border: 1px solid $primary;
    }
  }
}

.dark {
  input {
    & + span {
      &:before {
        border: 1px solid $darkGrey;
      }
    }
  }
}
