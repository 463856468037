.wrapper {
  margin-top: 40px;
  width: 100%;
  border: 1px solid rgba(0, 117, 255, 0.5);
  border-radius: 10px;
  position: relative;
  background: rgb(16,19,25);
  background: linear-gradient(167deg, rgba(16,19,25,1) 40%, rgba(0,117,255,0.1) 100%);
  padding: 28px 18px 24px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 10px;
    right: 0;
    height: 100%;
    top: 0;
    bottom: 0;
    width: 100px;
    z-index: 1;
    background: rgb(16,19,25);
    background: linear-gradient(90deg, rgba(16,19,25,0) 0%, rgba(16,19,25,1) 100%);
  }

  @media screen and (max-width: 480px) {
    &:after {
      display: none;
    }
  }
}

.title {
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  text-transform: uppercase;
  padding: 10px 34px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: #101319;
  top: -20px;
  white-space: nowrap;
}

.card {
  background: rgba(21, 35, 56, 1);
  border-radius: 10px;
  overflow: hidden;
  width: 320px;
  max-width: 100%;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.cardTop {
  padding: 16px;
  display: flex;
  align-items: center;
}

.avatar {
  display: flex;
  margin-right: 16px;
  background: conic-gradient(
      from 180deg at 50% 50%,
      #0075ff 0deg,
      rgba(21, 35, 56, 0.25) 360deg
  );
  border-radius: 50%;
}

.cardBottom {
  background: rgba(34, 44, 61, 1);
  padding: 12px 16px;
  display: flex;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.address {
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.tooltipWrapper {
  margin-left: 8px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;

  &:hover {
    .tooltip {
      display: block;
    }
  }

  .tooltipIcon {
    cursor: pointer;
    padding: 6px 0px;
  }

  .tooltip {
    display: none;
    position: absolute;
    background: rgba(23, 26, 34, 1);
    border-radius: 10px;
    padding: 4px 12px;
    top: 24px;
    right: -30px;

    div {
      white-space: nowrap;
      display: flex;
      align-items: center;
    }

    span {
      font-size: 12px;

      &:first-child {
        color: rgba(190, 190, 190, 1)
      }
      &:last-child {
        color: rgba(137, 138, 142, 1);
        margin-left: 4px;

        img {
          margin-left: 4px;
          cursor: pointer;
        }
      }
    }
  }
}

.grey {
  color: rgba(137, 138, 142, 1);
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
}

.white {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 8px;
}


.green {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(42, 229, 83, 1);
  margin-left: 8px;
}

.sum {
  color: rgba(242, 186, 47, 1);
  font-size: 15px;
  line-height: 15px;
  margin-bottom: 2px;

  b {
    font-weight: 500;
    color: rgba(242, 186, 47, 1);
    font-size: 15px;
    line-height: 15px;
  }
}

.wrapper {

}
