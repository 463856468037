.wrapper {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 95px;
  border-radius: 10px;
}


.valueBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 36px;
  line-height: 30px;
}

.gold {
  background: linear-gradient(319.99deg, rgba(255, 197, 49, 0.22) 2.69%, rgba(255, 197, 49, 0) 71.45%);
  border: 1px solid rgba(255, 197, 49);

  .valueBlock {
    color: #F2BA2F;
    text-shadow: 0px 0px 21px rgba(242, 186, 47, 0.3);

    & > * {
      color: #F2BA2F;
      text-shadow: 0px 0px 21px rgba(242, 186, 47, 0.3);
    }
  }
}

:global .odometer-digit {

  text-shadow: none !important;

  .odometer-value {
    color: #F2BA2F;
  }
}

.primary {
  background: linear-gradient(337.13deg, rgba(0, 117, 255, 0.1) -8.31%, rgba(0, 117, 255, 0) 70.16%);
  border: 1px solid #0075FF;

  .valueBlock {
    font-weight: 300;
    font-size: 24px;
    line-height: 15px;
  }
}

.icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}


.shadow {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F1F1F5;
  height: 1px;

  padding: 0 25px;
  position: absolute;
  left: 50%;
  top: -1px;
  transform: translate(-50%, 0);
}

.dark {
  .shadow {
    background: #101319;
  }
}

.titleBlock {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  white-space: nowrap;
}
