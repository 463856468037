$dark: #101319;
$white: #FFFFFF;

.navigateBlock {
  display: flex;
  align-items: center;
  gap: 30px;
  white-space: nowrap;

  * {
    text-decoration: none;
  }

}

.default {
  a {
    color: $dark;
  }

  a:active {
    color: $dark;
  }
}

.dark {
  a {
    color: $white;
  }

  a:active {
    color: $white;
  }

  background: $dark;
}

@media (max-width: 1024px) {
  .navigateBlock {
    display: none;
  }
}

@media (max-width: 860px) {
  .navigateBlock {
    gap: 5px;
  }
}
