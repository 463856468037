$blue: #0075FF;

.button {
  display: inline-flex;
  font-family: 'Inter', sans-serif;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  gap: 10px;
  user-select: none;
}

//! PRIMARY

.primary {
  background: #0075FF;
  color: #ffffff
}

.outlined-primary {
  border: 1px solid #0075FF;
  background: transparent;
  color: #0075FF;
}

.primary:hover {
  background: linear-gradient(0deg, rgba(16, 19, 25, 0.2), rgba(16, 19, 25, 0.2)), #0075FF;
}

.outlined-primary:hover {
  background: transparent;
}

.primary:active {
  background: linear-gradient(0deg, rgba(16, 19, 25, 0.4), rgba(16, 19, 25, 0.4)), #0075FF;
}

.outlined-primary:active {
  background: transparent;
}


// ! SECONDARY
.secondary {
  background: #08BD50;
  color: #ffffff
}

.outlined-secondary {
  border: 1px solid #08BD50;
  background: transparent;
  color: #08BD50;
}

.secondary:hover {
  background: linear-gradient(0deg, rgba(16, 19, 25, 0.2), rgba(16, 19, 25, 0.2)), #08BD50;
}

.outlined-secondary:hover {
  background: transparent;
}

.secondary:active {
  background: linear-gradient(0deg, rgba(16, 19, 25, 0.3), rgba(16, 19, 25, 0.3)), #08BD50;
}

.outlined-secondary:active {
  background: transparent;
}


// ! GREY
.grey {
  background: #F3F3F4;
  color: #101319;
}

.contained-grey-dark {
  background: rgba(255, 255, 255, 0.05);
  color: #FFFFFF;
}

.outlined-grey {
  border: 1px solid #CFD0D1;
  background: transparent;
  color: #898A8E;
}

.ghost-grey {
  background: transparent;
  border: 1px solid #CFD0D1;
  color: #898A8E;
}

.ghost-grey-dark {
  background: transparent;
  border: 1px solid #2E3138;
  color: #898A8E;
}

.grey:hover {
  background: linear-gradient(0deg, rgba(16, 19, 25, 0.05), rgba(16, 19, 25, 0.05)), #F3F3F4;
}

.contained-grey-dark:hover {
  background: rgba(255, 255, 255, 0.12);
  color: #FFFFFF;
}

.outlined-grey:hover {
  background: transparent;
}

.ghost-grey:hover {
  background: #F3F3F4;
  border: 1px solid #CFD0D1;
  color: #898A8E;
}

.ghost-grey-dark:hover {
  background: #1C1F25;
  border: 1px solid #2E3138;
  color: #898A8E;
}

.grey:active {
  background: linear-gradient(0deg, rgba(16, 19, 25, 0.1), rgba(16, 19, 25, 0.1)), #F3F3F4;
}

.contained-grey-dark:active {
  background: rgba(255, 255, 255, 0.17);
  color: #FFFFFF;
}

.ghost-grey:active {
  background: #F3F3F4;
}

.ghost-grey-dark:active {
  background: transparent;
}

.outlined-grey:active {
  background: transparent;
}

//! GREY-WEB3
.greyweb3 {
  border: 1px solid #2E3138;
  color: #ffffff;
  border-radius: 10px;
  background: transparent;
}


.outlined-greyweb3-default {
  color: black;

  &:hover {
    border-color: $blue;
  }
}

//! BLUE-WEB3
.blueweb3 {
  color: #ffffff
}

.outlined-blueweb3 {
  border: 1px solid transparent;
  background: #171A22;
}

.outlined-blueweb3-default {
  border: 1px solid rgba(0, 117, 255, 0.1);
  background: transparent;
  color: $blue;
}

.blueweb3:hover {
}

.outlined-blueweb3:hover {
}

.blueweb3:active {
}

.outlined-blueweb3:active {
}


//! BLUE
.ghost-blue,
.blue {
  background: rgba(0, 117, 255, 0.1);
  color: #0075FF;
}

.outlined-blue {
  border: 1px solid rgba(0, 117, 255, 0.1);
  background: transparent;
  color: #0075FF;
}

.ghost-blue:hover,
.blue:hover {
  background: rgba(0, 117, 255, 0.2);
}

.outlined-blue:hover {
  background: transparent;
}

.blue:active {
  background: rgba(0, 117, 255, 0.3);
}

.ghost-blue:active {
  background: rgba(0, 117, 255, 0.2);
}

.ghost-blue-dark {
  color: #0075FF !important;
}

.outlined-blue:active {
  background: transparent;
}

.error {
  border: 1px solid #FA3E2C;
}

.white {
  background: #FFFFFF;
}

.green {
  background: #0F1B1C;
  color: #08BD50 !important;
}

.red {
  background: #26171B;
  color: #FA3E2C !important;
}

.contained-white-dark {
  background: #171A22;
}

//! fullwidth
.fullwidth {
  width: 100%;
}

//! makeBet
.makeBet {
  display: flex;
  justify-content: center;
  position: fixed;
  right: 15px;
  bottom: 15px;
  left: 15px;
}

//! position toolTipText in button
.align-start {
  justify-content: start;
}

.align-center {
  justify-content: center;
}

.align-end {
  justify-content: end;
}


//! SIZE

.xl {
  padding: 20px 10px;
  min-width: 225px;

  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.02em;
  border-radius: 10px;
}

.lg {
  padding: 15px 10px;
  min-width: 225px;

  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}

.md {
  padding: 12.5px 10px;
  min-width: 225px;

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.mmd {
  padding: 15px;
  max-height: 50px;

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.sm {
  padding: 7.5px 15px;

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.xs {
  padding: 2.5px 7.5px;
  min-width: 40px;

  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
}

.disabled-default {
  cursor: none;
  pointer-events: none;
  background: #F3F3F4;
  color: #6b6a6a !important;
  border: none;
}

.disabled-dark {
  cursor: none;
  pointer-events: none;
  background: #23252D;
  color: rgba(255, 255, 255, 0.35) !important;
  border: none;
}


//! Border-radius

.br-left-disabled {
  border-radius: 0 5px 5px 0;
}

.br-right-disabled {
  border-radius: 5px 0 0 5px;
}

//! Icon Button

.icon-button {
  width: 25px;
  height: 25px;

  background: transparent;
}

.icon-button:hover {
  background: transparent;
}

.icon-button:active {
  background: transparent;
}


//! loading button option

.loadDefault {
  gap: 14px !important;
  color: #898A8E;
  background: #FFFFFF;
  cursor: none;
  pointer-events: none;

  &:hover {
    background: #FFFFFF;
  }
}

.loadDark {
  color: #898A8E !important;
  background: #171A22;
  cursor: none;
  pointer-events: none;

  &:hover {
    background: #171A22;
  }
}

.loader {
  animation: loading 1.2s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.none {
  background: transparent !important;
}

