$white: #ffffff;
$dark: #101319;
$default-grey: #cfd0d1;
$dark-grey: #2e3138;
$medium-grey: #898a8e;
$primary: #0075ff;

.wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 295px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: $white;
  padding: 0 34px 25px 34px;
  align-items: center;
  z-index: 4;
}

.logoBlock {
  height: 81px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 11px;
  padding-right: 10px;
}

.default {
  background: $white;
  border-right: 1px solid $default-grey;
}

.dark {
  background: #171a22;
  color: #ffffff;
  border-right: 1px solid $dark-grey;
}

.label {
  cursor: pointer;

  div:last-child {
    margin-left: 10px;
  }
}

.sideButtons {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: auto;

  div:first-child {
    margin-bottom: 15px;
  }

  div:nth-of-type(2),
  div:nth-of-type(3),
  div:nth-of-type(4),
  div:nth-of-type(5) {
    margin-bottom: 15px;
  }

  .makebet {
    margin-top: 25px;
  }
}

.menuLabel {
  cursor: pointer;
}

.list {
  display: flex;
  flex-direction: column;
  margin-bottom: 19px;
  padding-left: 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

@media (max-width: 1024px) {
  .wrapper {
    position: fixed;
    width: 100%;
    top: auto;
    left: 0;
    padding: 25px 15px;
    height: calc(100vh - 141px);
    display: block !important;
  }

  .wrapper.scrolled {
    overflow-y: scroll;
  }

  .list {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .default,
  .dark {
    border-right: none;
  }
}

.mobileMenu {
  min-height: 850px;
  height: 100%;

  .mlang {
    margin-top: 40px;
    margin-bottom: 30px;
  }
}
