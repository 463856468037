.text {
  line-height: 20px;
  user-select: none;

  &:before {
    float: right;
  }
}

.card {
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }
}

.install-wallet-btn {
  margin-top: 20px;
  padding: 5px;
}