.rate {
  font-size: 28px;
  font-weight: 600;
  @media (max-width: 720px) {
    margin-bottom: 20px;
  }
}

.source {
  margin-top: 20px;
  display: flex;
  align-items: center;

  img {
    cursor: pointer;
  }
}

.sourceTitle {
  opacity: 0.8;
  font-size: 12px;
  margin-right: 10px;
}

.tooltip {
  margin-left: 6px;
  display: flex;
  align-items: center;
}

.chart_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  @media (max-width: 720px) {
    flex-wrap: wrap;
  }
}
