.stringTabs {
  padding: 35px;
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  p {
    color: #898A8E;
  }

  @media (max-width: 1024px) {
    padding: 15px;
  }
}

.visible {
  p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.betButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  border: none;
  color: #195BDC !important;
}

.rotateChevron {
  rotate: 180deg;
}

.dark {
  background: #171A22;
}
