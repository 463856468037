$white: #FFFFFF;
$dark: #101319;
;
$default-grey: #CFD0D1;
$dark-grey: #2E3138;
$medium-grey: #898A8E;
$primary: #0075FF;

.itemList {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    flex: 1 1 50%;
  }

  a {
    color: $medium-grey;
  }

  a:before {
    content: '';
    display: inline-flex;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 20px;
    background: $medium-grey;
  }

  .active-default, .default:hover {
    color: $dark !important;
  }

  a.active-default:before, .default:hover:before {
    background: $primary;
  }

  .active-dark, .dark:hover {
    color: $white !important;
  }

  a.active-dark:before, .dark:hover:before {
    background: $primary;
  }
}



