.wrapper {
  position: relative;
  padding: 14px 13px 14px 15px;
  border-radius: 5px;
}

.default {
  border: 1px solid #CFD0D1;

}

.dark {
  border: 1px solid #2A2C33;
}

.slider {
  padding: 0 !important;
  height: auto !important;
  position: absolute !important;
  left: 0;
  bottom: 3px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input {
  background: transparent;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  width: 100%;
  max-width: 150px;

  @media screen and (max-width: 500px) {
    max-width: 130px;
  }
}

.btn {
  height: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.content-default {
  .btn {
    color: #898A8E;
    border: 1px solid #CFD0D1;
  }
}

.content-dark {
  .btn {
    background-color: transparent;
    color: #898A8E;
    border: 1px solid #2A2C33;
  }
}

.kit {
  display: flex;

  * {
    margin-left: 5px;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}
