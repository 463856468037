.mb_5 {
  margin-bottom: 5px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mt_10 {
  margin-top: 10px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_25 {
  margin-bottom: 25px;
}

.commissionBlock {
  display: flex;
  align-items: center;

  p {
    margin-right: 6px;
    color: #898A8E;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    color: #898A8E;
  }
}

.divider {
  width: 100%;
  height: 1px;

  background: #CFD0D1;
  margin-bottom: 10px;
}

.accordionSwitcherBlock {
  display: flex;
}

.dealBlock {
  p {
    color: #898A8E;
  }
}


.btnGroupTitle {
  display: flex;
  gap: 4px;
}

.btnGroup {
  display: flex;
  justify-content: space-between;
  gap: 15px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    width: 100%;
    border: 1px solid #CFD0D1;
    border-radius: 5px;
    color: #898A8E;
  }

  .active {
    border: none;
    color: #0075FF;
    background: rgba(0, 117, 255, 0.2);
  }
}

.dark {
  .divider {
    background: #2E3138;
  }

  .accordionSwitcherBlock {
    div {
      color: #898A8E;
    }
  }

  .btnGroup {
    div {
      border: 1px solid #2A2C33;
    }

    .active {
      color: inherit;
      border: none;
      background: #152338;
    }
  }
}

.betRow {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;

  &:not(:last-child) {
    border-bottom: 1px solid #2E3138;
  }

  span {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #898A8E;
  }
}

.showMore {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  cursor: pointer;
  margin-bottom: 15px;

  background: #171A22;
  border-radius: 5px;
}

.rotated {
  transform: rotateX(180deg);
}

.betsWrapper {
  @media screen and (max-width: 500px) {
    overflow: auto;
    max-height: 200px;
  }
}

.betRight {
  display: flex;
  align-items: center;
}

.removeBet {
  width: 11px;
  height: 11px;
  background-color: #171A22;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
}
