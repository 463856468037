$white: #FFFFFF;
$light-grey: #F9F9F9;
$grey: #898A8E;
$dark: #171A22;
$dark-odd-line: #1C1F27;
$over-dark: #101319;
$min-width: 430px;


.default {
  background: $white;
}

.table {
  position: relative;
  overflow: visible;

  * {
    white-space: nowrap;
  }

  border-collapse: collapse;
  width: 100%;
  min-width: $min-width;

  th, td {
    padding-left: 35px;
    @media (max-width: 1024px) {
      padding-left: 15px;
    }
  }

}

.thead {
  tr {
    height: 50px;

    th {
      text-align: start;
      font-weight: 400;
      font-size: 15px;
      line-height: 25px;
      color: $grey;
    }

    th:last-child {
      text-align: end;
      padding-right: 35px;
      padding-left: 0;
      @media (max-width: 1024px) {
        padding-right: 15px;
      }
    }
  }
}

.centerTwoColumn {
  th:nth-child(2) {
    width: 20%;
  }
}

.tbody {
  tr {
    td {
      text-align: start;
      font-weight: 400;
      font-size: 15px;
      line-height: 25px;
      color: $over-dark;
      height: 50px;
    }
  }

  td:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  tr:nth-child(odd) {
    background: $light-grey;
  }

  td:last-child {
    text-align: right;
    padding-right: 35px;
    padding-left: 0;
    @media (max-width: 1024px) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

.tbody-dark {
  tr {
    background: $dark;

    td {
      color: $white;
    }
  }

  tr:nth-child(odd) {
    background: $dark-odd-line;
  }
}

.showMore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25.5px;
  @media (max-width: 1024px) {
    margin: 15px 0;
  }
}

.betButton {
  border: none;
  color: #195BDC !important;
}

.rotateChevron {
  transform: rotate(180deg);
}
