.wrapper {
  display: flex;
}

.content {
  width: 100%;
  overflow: hidden;
}

.rightBlock {
  width: 100%;
  max-width: 335px;
  margin-left: 45px;
  @media (max-width: 1024px) {
    display: none;
  }
}

.blue {
  cursor: pointer;
  color: #195BDC;
  font-size: 15px;
}

.rowBet {
  justify-content: start;
  gap: 35px;

  > div:not(:nth-child(1), :nth-child(2), :nth-child(3)) {
    display: none;
  }
}

.totalBlock {
  margin-top: 6px;
  margin-bottom: 50px;
  @media (max-width: 1024px) {
    margin-bottom: 23px;
  }
}

.mobileTotal {
  display: none;
  @media (max-width: 1024px) {
    display: flex;
    margin-bottom: 25px;
  }
}

.title {
  width: 100%;
  padding: 35px;
  border-radius: 10px;
  margin-bottom: 25px;
  @media (max-width: 1024px) {
    margin-bottom: 15px;
  }
}

.schedule {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
  border: 1px solid grey;
  border-radius: 10px;
  margin-bottom: 25px;
  @media (max-width: 1024px) {
    margin-bottom: 15px;
  }
}

.tableTabs {
  margin-bottom: 25px;
  @media (max-width: 1024px) {
    margin-bottom: 15px;
  }
}

.strTabsBlock {
  margin-bottom: 25px;
  @media (max-width: 1024px) {
    margin-bottom: 15px;
  }
}

.chart {
  border-radius: 10px;
  overflow: auto;
  background-color: #FFF;
  padding: 30px;
  margin-bottom: 20px;

  &.dark {
    background: #171A22;
  }

  @media (max-width: 1024px) {
    padding: 15px;
  }
}

.dark {
  .title {
    background: #171A22;
  }
}

.default {
  .title {
    background: #FFFFFF;
  }
}
