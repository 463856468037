.main {
  &:not(:first-child) {
    margin-top: 45px;
  }
}

.wrapper {
  position: relative;
}

.largeWrapper {
  position: relative;
  margin-top: -10px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.emptyTitle {
  margin-bottom: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide toolTipText vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }

  &:hover {
    z-index: 1;
  }
}

/*Sizes of Item*/
.sm {
  width: 128px !important;
}

.md {
  width: 191px !important;
}

.lg {
  width: 335px !important;
  margin-top: 10px;
}

.lgg {
  width: 320px !important;

  @media screen and (max-width: 480px) {
    width: 100% !important;
  }
}

.arrowPrev,
.arrowNext {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: -26px;
  transform: translate(0, -50%);
  width: 25px;
  height: 25px;
  z-index: 2;
  cursor: pointer;
}

.arrowNext {
  right: -26px;
  left: unset;
}

.iconPrev {
  transform: rotate(90deg);
}

.iconNext {
  transform: rotate(-90deg);
}

.iconNext-dark,
.iconPrev-dark {
  path {
    stroke: #FFFFFF;
  }
}

.iconNext-default,
.iconPrev-default {
  path {
    stroke: #898A8E;
  }
}

.laptopLink {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1024px) {
  .arrowPrev,
  .arrowNext {
    display: none;
  }
}

@media (max-width: 480px) {
  .main {
    margin-top: 25px;
  }

  .hideNavigation {
    margin-top: 0 !important;
  }

  .title {
    margin-bottom: 15px;
  }

  .sm {
    width: 75px !important;
  }

  //.laptopWrapper {
  //  .item {
  //    margin-bottom: 65px;
  //  }
  //}
}
