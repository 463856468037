.wrapper {
  background: #FFFFFF;
  border-radius: 15px;
}

.main {
  padding: 22px 22px 15px 22px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}


.footer {
  padding: 15px 22px 22px 22px;
  display: flex;
  justify-content: space-between;
}

.divider {
  width: 100%;
  height: 1px;
  background: #CFD0D1;
}

.idBlock {
  padding: 5px 7px;
  border-radius: 5px;
  border: 1px solid #E1E1E1;
}


.dark {
  width: 335px;
  max-width: 335px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  border-radius: 10px;
  border-radius: 12px;

  .divider {
    background: #2E3138;
  }

  .idBlock {
    border: 1px solid #2E3138;
    background: rgba(255, 255, 255, 0.05);

  }

  .actionValueBlock {
    color: #FFFFFF;
  }

  .content {
    p:first-child {
      color: #FFFFFF;
    }
  }
}
