.inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.title {
  font-size: 24px;
  color: #FFF;
  margin-top: 30px;
  font-weight: bold;
}

.subtitle {
  font-size: 18px;
  margin-top: 20px;
}

.button {
  align-items: center;
  border: none;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  gap: 5px;
  height: 50px;
  justify-content: center;
  line-height: 19px;
  padding: 14px 16px;
  transition: all .5s;
  width: 125px;
}


.telegram .button {
  background: linear-gradient(180deg, #41bce7, #22a6dc 99.64%), #23252d;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  width: 100%;
}

.twitter .button {
  background: #52c1ff;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  width: 100%;
}

.discord .button {
  background: #6665d2;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  width: 100%;
}

.buttons {
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
  justify-content: center;

  & > * {

    margin: 15px;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    width: 100%;
  }
}


