$dark: #171A22;
$white: #FFFFFF;

.wrapper {
  border-radius: 10px;
  padding: 15.5px 0 25.5px 0;
  overflow: auto;
  background: #FFFFFF;

  @media (max-width: 1024px) {
    padding: 0;
  }
}

.dark {
  background: $dark;
}

.default {
  background: $white;
}

.betButton {
  border: none;
  color: #195BDC !important;
}

.rotateChevron {
  transform: rotate(180deg);
}

.showMore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25.5px;
  @media (max-width: 1024px) {
    margin: 15px 0;
  }
}