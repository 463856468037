.wrapper {
  display: flex;
  gap: 10px;
}

.container {
  width: 40px;
  height: 25px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  transition: all 0.3s;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    left: -99999px;
    top: -99999px;
    visibility: hidden;
  }

  &.dark {
    border: 1px solid #2a2c33;
    color: #898a8e;

    &.selected {
      color: #0075ff;
      background: #152338;
      border-color: #152338;
    }

    &:hover {
      background: #152338;
      border-color: #152338;
    }
  }

  &.default {
    border: 1px solid #cfd0d1;
    color: #898a8e;

    &.selected {
      color: #0075ff;
      background: rgba(0, 117, 255, 0.1);
      border-color: transparent;
    }

    &:hover {
      background: rgba(0, 117, 255, 0.1);
      border-color: rgba(0, 117, 255, 0.1);
    }
  }
}
