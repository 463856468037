.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 5px;
  height: 50px;
  padding: 15px 17.5px 15px 15px;
}

.dark {
  background: #101319;
  border: 1px solid #2E3138;
  color: #FFF;

}

.default {
  background: transparent;
  border: 1px solid #CFD0D1;
  color: #101319;

  svg {
    cursor: pointer;

    path {
      fill: #101319;
    }
  }
}

.input {
  width: 100%;
  padding-right: 17.5px;


  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;

  border: none;
}

.readOnly {
  color: #898A8E;
  cursor: default;
}

.label {
  margin-bottom: 10px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

