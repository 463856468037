$divider-color: #cfd0d1;
$dark-divider-color: #2e3138;
$dark: #101319;
$dark-side: #171a22;
$white: #ffffff;
$grey: #f1f1f5;

.wrapper {
  display: flex;
  align-items: center;
  background: $grey;
}

.crumb {
  transform: rotate(90deg);
}

.default {
  background: $grey;
}

.dark {
  background: $dark;

  * {
    color: $white;
  }
}

.sideBlock {
  display: flex;
  align-items: center;
  padding: 27px 44px 22.5px 45px;
  border-right: 1px solid $divider-color;
  overflow: hidden;
  width: 295px;
  height: 81px;
}

.sideBlock-default {
  border-bottom: 1px solid $divider-color;
}

.sideBlock-dark {
  border-right: 1px solid $dark-divider-color;
  border-bottom: 1px solid $dark-divider-color;
}

.sideBlock-default-false {
}

.sideBlock-default-true {
  border-bottom: none;
  background: $white;
}

.sideBlock-dark-false {
}

.sideBlock-dark-true {
  border-bottom: none;
  background: $dark-side;
}

.logoI {
  display: flex;
  margin-right: 20px;
}

//! nav

.navigateWrapper {
  padding: 22.5px 45px;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 295px);
  flex-wrap: wrap;
  border-bottom: 1px solid $divider-color;
}

.navigateWrapper-default {
}

.navigateWrapper-dark {
  border-bottom: 1px solid $dark-divider-color;
}

//! .connectBlock

.connectBlock {
  display: flex;
  align-items: center;
  height: 35px;

  .connectLeft {
    display: flex;
    align-items: center;

    .notification {
      display: flex;
      position: relative;
      margin-right: 20px;
      @media (max-width: 1180px) {
        margin-right: 0;
      }
    }

    .badge {
      pointer-events: none;
      position: absolute;
      right: -5%;
      top: 6%;
      z-index: 1;
    }

    .connectBtn {
      display: flex;
      align-items: center;

      .circleIcon-default {
        display: flex;
        margin-right: 10px;
        background: conic-gradient(
            from 180deg at 50% 50%,
            #0075ff 0deg,
            rgba(0, 117, 255, 0.25) 360deg
        );
        border-radius: 50%;
        @media (max-width: 1180px) {
          margin-right: 0;
        }
      }

      .circleIcon-dark {
        display: flex;
        margin-right: 10px;
        background: conic-gradient(
            from 180deg at 50% 50%,
            #0075ff 0deg,
            rgba(21, 35, 56, 0.25) 360deg
        );
        border-radius: 50%;
        @media (max-width: 1180px) {
          margin-right: 0;
        }
      }

      .box {
        width: 160px;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
        color: $dark;

        .text:before {
          float: right;
        }

        .text {
          line-height: 20px;
          user-select: none;
        }
      }
    }
  }
}

.menuLabel {
  cursor: pointer;
}

.innerHeader {
  display: none;
}

@media (max-width: 1180px) {
  .navigateWrapper {
    padding-right: 12px;
    // width: 100%;
    width: calc(100% - 245px);

    div {
      gap: 10px;
    }
  }
}

@media (max-width: 720px) {
  .navigateWrapper {
    width: 100%;
  }
}

@media (max-width: 420px) {
  .navigateWrapper {
    div {
      gap: normal;
    }
  }
}

@media (max-width: 1024px) {
  .rightIconBtn {
    margin-left: 15px;
  }
  .logoI {
    margin: 0;
  }
  .sideBlock {
    // padding: 22.5px 13px 22.5px 15px;
    padding: 22.5px 44px 22.5px 45px;
    border-right: none;
    background: transparent;
    border-bottom: 1px solid $divider-color;
  }
  .sideBlock-dark-true {
    border-bottom: 1px solid $dark-divider-color;
  }
  .sideBlock-dark-false {
    border-bottom: 1px solid $dark-divider-color;
  }
  .navigateWrapper {
    padding: 22.5px 15px 22.5px 0px;
    justify-content: end;
  }
  .connectBlock {
    .connectLeft {
      .notification {
        margin-right: 25px;
      }

      .connectBtn {
        display: none;
      }
    }
  }
  .innerHeader {
    display: flex;
    margin-bottom: 20px;
    padding-right: 10px;
    align-items: center;
    justify-content: space-between;
  }
  .innerHeader-default {
    background: transparent;
  }
  .innerHeader-dark {
    background: $dark-side;
    color: #ffffff;
  }
  .connectBlockMobile {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
      background: conic-gradient(
          from 180deg at 50% 50%,
          #0075ff 0deg,
          rgba(21, 35, 56, 0.25) 360deg
      );
      border-radius: 50%;
    }
  }
  .mobileCircle {
    display: flex;
  }

  .default {
    background: $grey;
  }
  .dark {
    background: $dark-side;

    * {
      color: $white;
    }
  }
  .textMobile {
    width: 140px;
    user-select: none;
    line-height: 20px;
  }

  .textMobile:before {
    float: right;
  }
}

@media (max-width: 780px) {
  .sideBlock {
    overflow: visible;
    padding: 27.5px 15px;
  }

  .wallet_button {
    font-size: 12px !important;
  }
}

.hamburger {
  margin-right: 5px;
}
