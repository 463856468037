$grey: #2E3138;
$white: #fff;
$blue: #0075FF;
$blue-2: #195BDC;
$black: #171A22;

// Базвые слитили
.base {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 10px 13px;
  border-radius: 10px;
  background: transparent;

  @media (any-hover: hover) {
    &:hover {
      border-color: $blue;
    }
  }
}

// Темная тема
.dark {
  &-border {
    &--default {
      border: 1px solid $grey;
    }

    &--install {
      border: none;
      background: $black;
    }
  }
}

// Светлая тема
.default {
  background: transparent;

  &-border {
    &--default {
      border: 1px solid $grey;
    }

    &--install {
      color: $grey !important;
    }
  }
}

.type-wallet {
  display: flex;
  align-items: center;

  gap: 10px;

  &__icon {
    width: 18px;
    height: 16px;
  }

  &__name {
    font-size: 12px;
    line-height: 15px;
  }
}

.base__install-wallet {
  font-size: 12px;
  line-height: 15px;

  color: $blue-2 !important;
}
