$primary: #0075FF;
$white: #FFFFFF;
$grey: #898A8E;
$dark: #101319;
$darkblue: #195BDC;
$low-dark: #171A22;
$green: #08BD50;
$red: #FA3E2C;
$cardTooltip: rgba(255, 255, 255, 0.5);

.primary {
  color: $primary !important;
}

.white {
  color: $white !important;
}

.red {
  color: $red !important;
}

.whiteSvg {
  path {
    fill: $white !important;
  }
}

.grey {
  color: $grey !important;
}

.dark {
  color: $dark !important;
}

.darkSvg {
  path {
    fill: $dark !important;
  }
}

.low-dark {
  path {
    fill: $low-dark !important;
  }
}

.darkblue {
  color: $darkblue !important;
}

.green {
  color: $green !important;
}

.cardTooltip {
  color: $cardTooltip !important;
}

.inherit {
  color: inherit;
}

//! по мере продвижения палетта будет добавляться

