.transactionBlock {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 15px;
  margin-top: 25px;
}

.transactionItem {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  padding: 15px;
  border-radius: 10px;
  flex-wrap: wrap;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .transactionItem {
    justify-content: center;
  }
}
