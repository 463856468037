$white: #FFFFFF;
$dark: #1c1f26;

.default {
  .content {
    background: $white;
    min-height: 50px;
  }

  *, *:before, *:after {
    box-sizing: border-box;
    position: relative;
  }
}

.dark {
  .content {
    background: rgba(30, 34, 43, 0.8);
    backdrop-filter: blur(15px);
    // background: $dark;
    min-height: 50px;
  }

  *, *:before, *:after {
    box-sizing: border-box;
    position: relative;
  }
}

.tooltipImage {
  position: absolute;
  width: 22px;
  height: 7px;
  top: 69px;
  right: 102px;
  z-index: 5;

  @media (max-width: 1024px) {
    display: none;
  }
}

.content {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 5;
  width: 100%;
  top: 75px;
  right: 15px;
  width: 300px;
  padding: 20px;
  border-radius: 10px;

  @media (max-width: 1024px) {
    position: fixed;
    left: 0;
    bottom: 0;
    top: auto;
    width: 100%;
    z-index: 5;
    padding: 15px;
    border-radius: 20px 20px 0px 0px;
    max-width: inherit;
    transform: none;
  }
}
