.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -500px;
  height: 100%;
  width: 450px;
  z-index: 10;
  border-left: 1px solid #2E3138;
  transition: right 0.3s ease;

  &.visible {
    right: 0px;
  }

  &.small {
    height: 400px;
  }

  @media screen and (max-width: 500px) {
    transition: top 0.3s ease;
    width: 100%;
    bottom: 0;
    top: calc(100% + 100px);
    left: 0;
    right: 0;

    &.visible {
      top: 80px;

      &.small {
        top: calc(100vh - 400px);
      }

      &:before {
        content: "";
        display: block;
        position: fixed;
        top: -80px;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(50px);
      }
    }
  }
}

.pane {
  height: 100%;
  background-color: rgba(23, 26, 34, 0.8);
  backdrop-filter: blur(25px);

  @media screen and (max-width: 500px) {
    border-radius: 15px 15px 0px 0px;
  }
}

.content {
  padding: 65px 48px;
  overflow: auto;
  height: 100%;

  @media screen and (max-width: 500px) {
    padding: 24px 24px 104px 24px;
  }
}

.close {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  cursor: pointer;
  background: #171A22;
  border: 1px solid #2E3138;
  position: absolute;
  z-index: 1;
  top: 60px;
  left: 0px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 500px) {
    border: none;
    top: -60px;
    left: auto;
    right: 0px;
  }
}


