$light: #ffffff;
$dark: #13161e;

.wrapper {
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 48px;
  height: 31px;
}

.canvas {
  // height: 100%;
  z-index: 1;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid;

  &.noBorder {
    border: none;
  }
}

.default {
  background: $light;
  border-color: $light;
}

.dark {
  background: $dark;
  border-color: $dark;
}

.rightIcon {
  position: absolute;
  right: 0;
  top: 3px;
  display: flex;
  z-index: 0;
}

.small {
  width: 28px;
  height: 20px;

  .canvas {
    border: 3px solid rgba(34, 44, 61, 1);
  }
}

.largeBlock {
  transform: scale(0.9);
  width: 76px;
  height: 50px;

  .rightIcon {
    top: 5px;
  }

  .canvas {
    max-width: 50px;
    width: 100%;
  }

  img,
  svg {
    width: 40px;
    height: 40px;
  }
}
