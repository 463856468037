$white: #FFFFFF;
$blue: #0075FF;
$width: 260px;

.tooltip {
  position: relative;
  display: inline-flex;
}

.tooltip .tooltipText {
  visibility: hidden;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  color: $white;
  background: $blue;
  z-index: 1;
  opacity: 0;
  transition: opacity 1s;
}

.tooltip-top .tooltipText-top {
  max-width: $width;
  width: max-content;
  bottom: 140%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-bottom .tooltipText-bottom {
  max-width: $width;
  width: max-content;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-left .tooltipText-left {
  width: max-content;
  top: -5px;
  right: 145%;
}

.tooltip-right .tooltipText-right {
  width: max-content;
  top: -5px;
  left: 145%;
}

.tooltip-top .tooltipText-top::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: $blue transparent transparent transparent;
}

.tooltip-bottom .tooltipText-bottom::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent $blue transparent;
}

.tooltip-right .tooltipText-right::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent $blue transparent transparent;
}

.tooltip-left .tooltipText-left::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent $blue;
}

.tooltip:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}


