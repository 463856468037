.tabsBlock {
  display: flex;
  white-space: nowrap;
  overflow: auto;

  font-size: 15px;
  line-height: 25px;

  padding: 0 35px;
  color: #101319;

  div:not(:last-child) {
    margin-right: 30px;
  }

  @media (max-width: 1024px) {
    padding: 0 15px;
  }
}

.pageStyles {
  h1 {
    color: #898A8E;
  }
}

.target {
  padding-bottom: 9px;
  border-bottom: 3px solid #0075FF;
}

.default {
  .targetPageStyle {
    h1 {
      color: #101319;
    }
  }
}


.cursor {
  cursor: pointer;
}


.divider {
  width: 100%;
  height: 1px;
  background: #CFD0D1;
  margin-bottom: 25px;
}

.dark {
  .divider {
    background: #2E3138;
  }

  .targetPageStyle {
    h1 {
      color: #FFFFFF;
      font-weight: 500;
    }
  }
}
