.label {
  display: flex;
  white-space: nowrap;
  align-items: center;
  border: 1px solid #2E3138;
  padding: 4px 12px 4px 6px;
  border-radius: 10px;
  background: linear-gradient(0deg, #171A22, #171A22), #0075FF;

  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #FFF;
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mb_5 {
  margin-bottom: 5px;
}

.mb_20 {
  margin-bottom: 20px;
}

.headRow {
  display: flex;
  overflow-x: auto;
  margin-bottom: 10px;
  margin-top: 25px;
  padding-bottom: 10px;
  gap: 25px;
}

.ceil {
  display: flex;
  align-items: center;
  gap: 9px;
  white-space: nowrap;
}

.divider {
  height: auto;
  padding-right: 1px;
  background: #2E3138;
}

.pane {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 24px;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  color: #FFFFFF;
  margin-bottom: 25px;
}

.percentItem:not(:last-child) {
  margin-bottom: 25px;
}

.accordionSwitcherBlock {
  display: flex;
  margin-bottom: 20px;

  & > * {
    justify-content: center;
  }
}

.betTitle {
  margin-bottom: 5px;
}

.buttons {
  margin-top: 25px;

  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
}

.commissionBlock {
  display: flex;
  align-items: center;

  p {
    margin-right: 6px;
    color: #898A8E;
  }
}

.mb_10 {
  margin-bottom: 10px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    color: #898A8E;
  }
}

.divider-horizontal {
  width: 100%;
  height: 1px;

  background: #2E3138;
  margin-bottom: 10px;
}

.bet {
  margin-bottom: 30px;
}


.btnGroupTitle {
  display: flex;
  margin-bottom: 4px;
}

.btnGroup {
  display: flex;
  justify-content: space-between;
  gap: 15px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    width: 100%;
    border: 1px solid #CFD0D1;
    border-radius: 5px;
    color: #898A8E;
  }

  .active {
    border: none;
    color: #0075FF;
    background: rgba(0, 117, 255, 0.2);
  }
}

.divider {
  background: #2E3138;
}

.accordionSwitcherBlock {
  div {
    color: #898A8E;
  }
}

.btnGroup {
  div {
    border: 1px solid #2A2C33;
  }

  .active {
    color: inherit;
    border: none;
    background: #152338;
  }
}


.dealBlock {
  p {
    color: #898A8E;
  }
}


.betsWrapper {
  @media screen and (max-width: 500px) {
    overflow: auto;
    max-height: 200px;
  }
}

.betRight {
  display: flex;
  align-items: center;
}

.removeBet {
  width: 11px;
  height: 11px;
  background-color: #171A22;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
}

.betRow {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;

  &:not(:last-child) {
    border-bottom: 1px solid #2E3138;
  }

  span {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #898A8E;
  }
}

.showMore {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  cursor: pointer;
  margin-bottom: 15px;

  background: #171A22;
  border-radius: 5px;
}

.rotated {
  transform: rotateX(180deg);
}

