$mobile-blurred-blue-dark: #002653;

.content-modal {
  display: flex;
  flex-direction: column;
}

.text {
  line-height: 20px;
  user-select: none;

  &--space {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &:before {
    float: right;
  }
}

.card {
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }
}

.walletDontHaveButtonMob {
  display: block;
  margin-top: 10px;
  padding: 20px;
}

.blurDiv {
  height: 60px;
  width: 100%;
  filter: blur(8px);
  opacity: 0.4;
}

.dark {
  .blurDiv {
    background: $mobile-blurred-blue-dark;
  }
}