.wrapper {
  svg {
    pointer-events: none;
  }

  div:nth-child(2) {
    svg {
      rotate: 180deg;
    }
  }
}


.not-active {
  path {
    fill: #ACADB0
  }
}
