:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  --toastify-toast-width: 420px;
  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

.Toastify__toast {
  border-radius: 15px;
  backdrop-filter: blur(25px);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  background: rgba(0, 117, 255, 0.25);
  border: 1px solid rgba(0, 117, 255, 0.5);

}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: rgba(83, 222, 124, 0.25);
  border: 1px solid rgba(92, 225, 131, 0.5);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: rgba(243, 186, 47, 0.25);
  border: 1px solid rgba(243, 186, 47, 0.5);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background: rgba(252, 51, 53, 0.25);
  border: 1px solid rgba(252, 51, 53, 0.5);
}

.Toastify__toast-body {
  padding: 0;
}

.cookies-notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
}

.cookies-notification-center {
  display: flex;

}

.notification-notif .Toastify__toast-icon {
  width: 40px;
}

.accept-cookies-button {
  border-radius: 8px;
  margin-left: 10px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
  background-color: white !important;
  color: #2E3138;
  white-space: nowrap;
}


.black-background {

  background: rgba(171, 185, 223, 0.15) !important;
  border: 1px solid rgba(171, 185, 223, 0.35);
}

.cookies-notification p {
  color: white;
}

.cookies-notification p span {
  font-weight: bold;
}

@media (max-width: 480px) {
  .Toastify__toast {

    margin-bottom: 10px !important;
  }

  .Toastify__toast-container {
    padding: 0 10px;
  }

  .accept-cookies-button {
    padding: 0.6em 0.6em;
  }

  .accept-cookies-button {
    margin-left: 0px;
  }

  .cookies-notification-center {
    margin-bottom: 10px;
  }

  .cookies-notification {
    flex-wrap: wrap;
    justify-content: center;
  }

  .accept-cookies-button {
    width: 100%;
  }
}
