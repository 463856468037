.mb_5 {
  margin-bottom: 5px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mt_10 {
  margin-top: 10px;
}

.mb_20 {
  margin-bottom: 20px;
  padding-bottom: 15px;
}

.mb_25 {
  margin-bottom: 25px;
}

.commissionBlock {
  display: flex;
  align-items: center;

  p {
    margin-right: 6px;
    color: #898A8E;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;

  div {
    color: #898A8E;
  }
}

.divider {
  width: 100%;
  height: 1px;

  background: #CFD0D1;
  margin-bottom: 10px;
}

.accordionSwitcherBlock {
  display: flex;
}

.dealBlock {
  p {
    color: #898A8E;
  }
}


.btnGroupTitle {
  display: flex;
  gap: 4px;
}

.btnGroup {
  display: flex;
  justify-content: space-between;
  gap: 15px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    width: 100%;
    border: 1px solid #CFD0D1;
    border-radius: 5px;
    color: #898A8E;
  }

  .active {
    border: none;
    color: #0075FF;
    background: rgba(0, 117, 255, 0.2);
  }
}

.dark {
  .divider {
    background: #2E3138;
  }

  .accordionSwitcherBlock {
    div {
      color: #898A8E;
    }
  }

  .btnGroup {
    div {
      border: 1px solid #2A2C33;
    }

    .active {
      color: inherit;
      border: none;
      background: #152338;
    }
  }
}

.rates {
  display: flex;
  margin-bottom: 20px;

  .rate {
    width: 100%;
    height: 60px;
    border-radius: 5px;
    padding-left: 30px;
    padding-top: 10px;

    & > div:first-child {
      color: #898A8E;
      font-size: 12px;
    }

    & > div:nth-child(2) {
      font-size: 20px;
      font-weight: 500;
      margin-top: 2px;
    }

    &:first-child {
      background-color: #171A22;
    }
  }
}
