$green: #08BD50;
$orange: #F7931A;
$yellow: #E9D111;
$grey: #ACADB0;
$red: #FA3E2C;

.blob {
  display: inline-flex;
  border-radius: 50%;
  margin: 8px;
  height: 10px;
  width: 10px;
}

/*! open */

.open {
  background: $green;
  box-shadow: rgba(8, 189, 80, 0.15);
  transform: scale(1);
  animation: pulse-open 2s infinite;
}

@keyframes pulse-open {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $green;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/*! accepted */
.accepted {
  background: $orange;

  box-shadow: rgba(247, 147, 26, 0.15);
  transform: scale(1);
  animation: pulse-accepted 2s infinite;
}

@keyframes pulse-accepted {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $orange;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/*! waiting */
.waiting {
  background: $yellow;

  box-shadow: rgba(233, 209, 17, 0.15);
  transform: scale(1);
  animation: pulse-waiting 2s infinite;
}

@keyframes pulse-waiting {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $yellow;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/*! closed */
.closed {
  background: $grey;

  box-shadow: rgba(172, 173, 176, 0.15);
  transform: scale(1);
  animation: pulse-closed 2s infinite;
}

@keyframes pulse-closed {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $grey;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}


/*! canceled */
.canceled {
  background: $red;

  box-shadow: rgba(250, 62, 44, 0.15);
  transform: scale(1);
  animation: pulse-canceled 2s infinite;
}


@keyframes pulse-canceled {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $red;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.badge {
  border-radius: 50%;
  margin: 5px;
  height: 7px;
  width: 7px;
  transform: scale(1);
  animation: pulse-badge 2s infinite;
}


@keyframes pulse-badge {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $red;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
