.headRow {
  display: flex;
  overflow-x: auto;
  margin-bottom: 10px;
  padding-bottom: 10px;
  gap: 35px;
}

.ceil {
  display: flex;
  align-items: center;
  gap: 9px;
  white-space: nowrap;
}

.divider {
  height: auto;
  padding-right: 1px;
}


.dark {
  background: #101319;
  color: #FFFFFF;

  .divider {
    background: #2E3138;
  }
}

.default {
  .divider {
    background: #CFD0D1;
  }
}
