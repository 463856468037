$dark: #171A22;
$white: #FFFFFF;

.wrapper {
  position: absolute;
  width: 250px;
  z-index: 3;

  display: flex;
  flex-direction: column;
  padding: 15px 15px 18px 15px;
  justify-content: space-between;

  background: $white;
  box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.btnWrap {
  display: inline-flex;
}

.dark {
  background: $dark;
  box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.25);
}

.none {
  display: none !important;
}

.wrapper-right {
  left: 210px;
  bottom: -15px;
}

.wrapper-bottom {
  left: -203px;
  top: 42px
}

.wrapper-left {
  top: -12px;
  right: 38px;
  width: auto;
}

.triangle {
  position: absolute;
  left: -8px;
  bottom: 16px;
}

.triangleTop {
  position: absolute;
  top: -16px;
  right: 20px;
  rotate: 90deg;
}

.triangleRight {
  position: absolute;
  top: 12px;
  right: -8px;
  rotate: 180deg;
}

.overlay {
  display: none;
}

.closeBtn {
  display: none;
}

.closeBtn:hover {
  background: $white;
}

.container {
  position: relative;
}

.swipe {
  position: absolute;
  width: 34px;
  height: 4px;
  left: calc(50% - 34px / 2);
  top: -10px;

  background: #FFFFFF;
  opacity: 0.2;
  border-radius: 2.5px;
}


@media (max-width: 1024px) {
  .withMobile {
    .wrapper {
      width: 100%;
      position: fixed;
      left: 0;
      bottom: 0;
      top: auto;
      border-radius: 20px 20px 0 0;

      .triangleTop {
        display: none;
      }
    }

    .closeBtn {
      position: absolute;
      display: flex;
      justify-content: center;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background: $white;
      align-items: center;
      right: 15px;
      top: -60px;
    }

    .closeBtnDark {
      background: $dark;

      svg {
        path {
          fill: $white;
        }
      }
    }

    .overlay {
      display: block;
      background: rgba(0, 0, 0, 0.75);
      backdrop-filter: blur(15px);
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 3;
    }
  }
}


