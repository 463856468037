$dark: #101319;
$grey: #F1F1F5;
$white: #FFFFFF;

* {
  padding: 0;
  margin: 0;
  border: 0;
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input, button, textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1, h2, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

// removed h3;


body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  -webkit-tap-highlight-color: transparent;
}


.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

.wrapper {
  display: flex;
  height: 100%;
  min-height: calc(100vh - 81px);
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 45px;
  background: $grey;

  &.leaderboard {
    background: #0f1318;
  }
}

.content-open {
  padding-left: calc(295px + 45px);
}


@media (max-width: 1024px) {
  .wrapper {
    height: 100%;
    min-height: calc(100vh - 81px - 60px);
  }
  .dark {
    background: $dark;
  }
  .default {
    background: $white;
  }

  .content {
    min-height: calc(100vh - 81px);
    padding: 25px 15px 12px 15px;
    background: $grey;
  }
}

.dark {
  position: relative;
  background: $dark;
  z-index: 2;

  * {
    color: $white;
  }
}

.portfolio {
  position: relative;

  .dark {
    z-index: 1;
  }
}

@media (min-width: 656px) {
  .portfolio::before {
    content: '';
    pointer-events: none;
    position: absolute;
    width: 507px;
    height: 515px;
    left: 25%;
    top: -221px;

    background: rgba(0, 117, 255, 0.15);
    filter: blur(100px);
    z-index: 2;
  }

  .portfolio::after {
    content: '';
    position: absolute;
    width: 326px;
    height: 326px;
    right: 0;
    pointer-events: none;
    top: 210px;

    background: rgba(0, 211, 85, 0.15);
    filter: blur(100px);
    z-index: 1;
  }
}


.portfolio {
  overflow: hidden;
}

@media (max-width: 656px) {

  .portfolio::before {
    content: '';
    position: absolute;
    width: 201px;
    height: 205px;
    pointer-events: none;
    left: 220px;
    top: 121px;

    background: rgba(0, 117, 255, 0.3);
    filter: blur(100px);
    z-index: 2;
  }

  .portfolio::after {
    content: '';
    position: absolute;
    width: 326px;
    pointer-events: none;
    height: 326px;
    left: -257px;
    bottom: 0px;

    background: rgba(0, 211, 85, 0.3);
    filter: blur(100px);
    z-index: 1;
  }
}

